import React from 'react';
import { Box, Grid } from '@mui/material';
import ServiceCard from './ServiceCard';
import bogføring from '../assets/bogføring.png';
import årsregnskab from '../assets/årsregnskab.png';
import heroImage from '../assets/hero-image.jpg';
import udkørsel from '../assets/udkørsel.png';


// Replace these with the actual images you want to use
const image1 = bogføring;
const image2 = årsregnskab;
const image3 = udkørsel;

const services = [
  {
    title: 'Kontorrengøring',
    description: 'Professionel rengøring af kontorer, der sikrer et rent og indbydende arbejdsmiljø for både medarbejdere og besøgende.',
    image: image1
  },
  {
    title: 'Flytterengøring',
    description: 'Grundig rengøring før og efter flytning, så du kan overdrage din lejlighed i topstand til boligselskabet eller den nye ejer.',
    image: image2
  },
  {
    title: 'Fast Rengøringsaftale',
    description: 'Regelmæssig rengøring på aftalte tidspunkter der sikrer dit hjem eller erhvervsejendom altid er pænt og rent.',
    image: image3
  },
];



function ServicesSection() {
  return (
    <Box my={1}>
      <Grid container spacing={1}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ServiceCard
              image={service.image}
              title={service.title}
              description={service.description}
              price={service.price} // Pass the index (incremented by 1) to ServiceCard
              index={index}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default ServicesSection;
