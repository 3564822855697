import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormHelperText,
} from '@mui/material';
import { useForm, ValidationError } from '@formspree/react';

function FormDialog({ open, handleClose }) {
  const [form, setForm] = useState({
    firmanavn: '',
    cvr: '',
    email: '',
    telefonnummer: '',
  });

  const [errors, setErrors] = useState({});
  const [state, handleSubmit] = useForm("xvoenwez"); // Replace with your Formspree endpoint ID

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!form.firmanavn) {
      newErrors.firmanavn = 'Firmanavn er påkrævet';
    }

    if (!form.email || !/\S+@\S+\.\S+/.test(form.email)) {
      newErrors.email = 'Ugyldig email';
    }

    // Remove white spaces and format phone number
    let formattedTelefonnummer = form.telefonnummer.replace(/\s+/g, '');

    // Remove country code if present
    if (formattedTelefonnummer.startsWith('0045')) {
      formattedTelefonnummer = formattedTelefonnummer.substring(4);
    } else if (formattedTelefonnummer.startsWith('+45')) {
      formattedTelefonnummer = formattedTelefonnummer.substring(3);
    }

    if (!formattedTelefonnummer || isNaN(formattedTelefonnummer) || formattedTelefonnummer.length !== 8) {
      newErrors.telefonnummer = 'Telefonnummer skal være 8 cifre';
    } else {
      form.telefonnummer = formattedTelefonnummer; // Update the form state with the formatted number
    }

    return newErrors;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const newErrors = validateForm(form);

    if (Object.keys(newErrors).length === 0) {
      // No errors, proceed with form submission
      handleSubmit(event).then(() => {
        if (state.succeeded) {
          alert('Tak for din interesse. Vi kontakter dig hurtigst muligt!');
          setForm({
            firmanavn: '',
            cvr: '',
            email: '',
            telefonnummer: '',
          });
          setErrors({});
        }
      });
      handleClose();
    } else {
      // Set errors to display error messages
      setErrors(newErrors);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Få et uforpligtende tilbud</DialogTitle>
      <DialogContent>
        <form id="contactForm" onSubmit={handleFormSubmit} style={{marginTop: '1rem'}}>
          <TextField
            label="Firmanavn"
            name="firmanavn"
            value={form.firmanavn}
            onChange={handleChange}
            error={!!errors.firmanavn}
            helperText={errors.firmanavn}
            style={{marginTop: '1rem'}}
            fullWidth
          />
          <TextField
            label="CVR"
            name="cvr"
            type="number"
            value={form.cvr}
            onChange={handleChange}
            error={!!errors.cvr}
            helperText={errors.cvr}
            style={{marginTop: '1rem'}}
            fullWidth
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            value={form.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
            style={{marginTop: '1rem'}}
            fullWidth
          />
          <TextField
            label="Telefonnummer"
            name="telefonnummer"
            value={form.telefonnummer}
            onChange={handleChange}
            error={!!errors.telefonnummer}
            helperText={errors.telefonnummer}
            style={{marginTop: '1rem'}}
            fullWidth
          />
          <input type="hidden" name="_gotcha" style={{display:"none !important"}} />
          <DialogActions>
            <Button onClick={handleClose}>Annuller</Button>
            <Button type="submit" disabled={state.submitting}>Send</Button>
          </DialogActions>
        </form>
        {state.errors && state.errors.map(error => (
          <FormHelperText key={error.field}>{error.message}</FormHelperText>
        ))}
      </DialogContent>
    </Dialog>
  );
}

export default FormDialog;
