// src/components/Services.js
import React from 'react';
import Page from './Page';
import image1 from '../assets/bogføring.png';
import image2 from '../assets/årsregnskab.png';
import image3 from '../assets/udkørsel.png';


const Service0 = () => {
  const title = "Kontorrengøring";
  const subtitle = "Professionel rengøring af arbejdspladser";
  const text = `Med vores kontorrengøringsservice sikrer vi, at dit arbejdsområde altid er rent og indbydende. Tjenesten inkluderer:

  - Grundig rengøring af alle kontorområder.
  - Støvsugning, aftørring og desinfektion af overflader.
  - Fleksibel tidsplanlægning til minimal forstyrrelse af din arbejdsdag.

  Lad os hjælpe med at skabe et sundere arbejdsmiljø for dig og dine medarbejdere.`;

  return (
    <Page
      title={title}
      subtitle={subtitle}
      text={text}
      image={image1}
    />
  );
};

const Service1 = () => {
  const title = "Flytterengøring";
  const subtitle = "Grundig rengøring før flytning";
  const text = `Vores flytterengøringstjeneste sørger for, at din gamle bolig efterlades pletfri. Denne service omfatter:

  - Dybderengøring af alle rum.
  - Vask og polering af gulve og vinduer.
  - Særlig opmærksomhed på køkken og bad.

  Gør flytningen lettere med en professionel rengøring inden boligoverdragelsen.`;

  return (
    <Page
      title={title}
      subtitle={subtitle}
      text={text}
      image={image2}
    />
  );

};

const Service2 = () => {
  const title = "Gentagen Rengøringsaftale";
  const subtitle = "Regelmæssig og pålidelig rengøring";
  const text = `Med en fast rengøringsaftale sørger vi for, at dit hjem eller kontor altid er rent. Denne service inkluderer:

  - Regelmæssig rengøring efter aftale.
  - Fast ugedag og tidspunkt for besøg.
  - Omfattende rengøring der tilpasses dine behov og præferencer.

  Nyd godt af et altid rent miljø uden besværet.`;

  return (
    <Page
      title={title}
      subtitle={subtitle}
      text={text}
      image={image3}
    />
  );
};

export { Service0, Service1, Service2 };
