import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const ImageWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  paddingBottom: '100%',
  overflow: 'hidden',

  [theme.breakpoints.down('sm')]: { // targets screens smaller than or equal to 600px width
    '&:hover img': {},
    '&:hover .service-text': {},
    '&:hover .service-index': {},
    '&:hover .text-color': {},
  },

  [theme.breakpoints.up('md')]: { // targets screens larger than or equal to 960px width
    '&:hover img': {
      opacity: 0.5,
    },
    '&:hover .service-text': {
      transform: 'translate(20%, -50px) scale(1.4)',
      color: 'red',
    },
    '&:hover .service-index': {
      transform: 'translate(20%, 10px) scale(1.2)',
      color: 'black',
    },
    '&:hover .text-color': {
      color: 'black',
      textShadow: 'none',
    },
  },
}));



const ServiceImage = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transition: 'opacity 0.3s',
});

const ServiceText = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '10%',
  left: '5%',
  textAlign: 'left',
  width: '60%',
  transition: 'transform 0.3s',
}));

const ServiceIndex = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '5%',
  left: '5%',
  color: 'white',
  fontWeight: 'bold',
  transition: 'transform 0.3s',
}));


const StyledTitle = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontWeight: 'bold',
  textShadow: '1px 1px 2px black',
  transition: 'color 0.3s, text-shadow 0.3s',
}));

const StyledDescription = styled(Typography)(({ theme }) => ({
  color: 'white',
  textShadow: '1px 1px 2px black',
  transition: 'color 0.3s, text-shadow 0.3s',
}));


function ServiceCard({ image, title, description, price, index }) {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/service${index}`)
  };


  return (
    <ImageWrapper onClick={handleClick} >
      <ServiceImage src={image} alt={title} />
      <ServiceText className="service-text">
        <StyledTitle className="text-color" variant="h5">{title}</StyledTitle>
        <StyledDescription className="text-color" variant="body1">{description}</StyledDescription>
      </ServiceText>
      {/*<ServiceIndex className="service-index" variant="h5">
        {price}
      </ServiceIndex>*/}
    </ImageWrapper>
  );
}

export default ServiceCard;