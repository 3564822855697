import React, {useState} from 'react';
import { Box, Typography, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { styled } from '@mui/system';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useNavigate } from 'react-router-dom';


const FooterContainer = styled(Box)({
  backgroundColor: '#333',
  color: 'white',
  padding: '5rem',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  gap: '1rem',
});

const FooterTitle = styled(Typography)({
  marginBottom: '1rem',
  fontWeight: 'bold',
});

const FooterItem = styled(Typography)({
  marginBottom: '0.5rem',
});

const SocialMediaContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '0.5rem',
});

function Footer() {

  const [openKontakt, setOpenKontakt] = useState(false);

  const handleClickOpenKontakt = () => {
    setOpenKontakt(true);
  };

  const handleCloseKontakt = () => {
    setOpenKontakt(false);
  };

  return (
    <FooterContainer>
      <div>
        <FooterTitle variant="h6">Åbningstider</FooterTitle>
        <FooterItem>Man - Fre: 9:00 - 18:00</FooterItem>
        <FooterItem>Lør: 10:00 - 16:00</FooterItem>
        <FooterItem>Søn: 10:00 - 16:00</FooterItem>
      </div>
      <div>
        <FooterTitle variant="h6">Kontakt</FooterTitle>
        <FooterItem>Tlf: +45 91 64 79 65</FooterItem>
        <FooterItem>Email: kontakt@multiclean.dk</FooterItem>
        {/*<FooterItem>Adresse: (Kontor addresse) </FooterItem>*/}
      </div>
      <div>
        <FooterTitle variant="h6">Links</FooterTitle>
        <FooterItem><Typography component="a" color="white" sx={{textDecoration: 'none'}} href="#home" >Hjem</Typography></FooterItem>
        <FooterItem><Typography  component="a" color="white" sx={{textDecoration: 'none'}} href="#tjenester"  >Tjenester</Typography></FooterItem>
        <FooterItem><Typography component="a" color="white" sx={{textDecoration: 'none'}} href="#omOs"  >Om Os</Typography></FooterItem>
        <FooterItem><Typography component="a" color="white" sx={{textDecoration: 'none'}} onClick={() => handleClickOpenKontakt() }  >Kontakt</Typography></FooterItem>
      </div>
      {/*<div>
        <FooterTitle variant="h6">Følg Os</FooterTitle>
        <SocialMediaContainer>
          <IconButton color="inherit" aria-label="Facebook">
            <FacebookIcon />
          </IconButton>
          <IconButton color="inherit" aria-label="Twitter">
            <TwitterIcon />
          </IconButton>
          <IconButton color="inherit" aria-label="LinkedIn">
            <LinkedInIcon />
          </IconButton>
          <IconButton color="inherit" aria-label="Instagram">
            <InstagramIcon />
          </IconButton>
        </SocialMediaContainer>
      </div>*/}
        <Dialog open={openKontakt} onClose={handleCloseKontakt}>
          <DialogTitle>Contact Information</DialogTitle>
          <DialogContent>
            <DialogContentText>Tlf: +45 30 257 251</DialogContentText>
            <DialogContentText>Email: info@regnskab1.nu</DialogContentText>
            <DialogContentText>Adresse: Møllergade 16, 2. sal, 5700 Svendborg</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseKontakt}>Close</Button>
          </DialogActions>
        </Dialog>
    </FooterContainer>
  );
}

export default Footer;
