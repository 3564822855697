import React, { useState } from 'react';
import FormDialog from './FormDialog';
import { AppBar, Toolbar, Typography, Box, useTheme, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, useMediaQuery} from '@mui/material';
import styled from '@emotion/styled';
import heroImage from '../assets/hero-image.png';
import heroImagePhone from '../assets/hero-image.png';
import logo from '../assets/Logo.png';
import { keyframes } from '@emotion/react';


const Hero = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '65vh',
  backgroundImage: `url(${heroImage})`,
  backgroundPosition: 'top center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  '@media (max-width: 600px)': {
    backgroundSize: 'cover',
    backgroundImage: `url(${heroImagePhone})`,
  },
  backgroundAttachment: 'fixed',
}));

const Overlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  //backgroundColor: 'rgba(0, 0, 0, 0.4)',
});

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;


const underlineAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

const StyledToolbar = styled(Toolbar)({
  justifyContent: 'flex-start',
  position: 'absolute',
  left: '4%',
  '& a': {
    textDecoration: 'none',
    marginRight: '1rem',
    fontSize: '0.8rem',
    position: 'relative',
    '&:hover::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      borderBottom: '2px solid white',
      animation: `${underlineAnimation} 0.5s linear forwards`,
    },
  },
});

const ActionButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '55%',
  left: '4%',
  color: 'white',
  backgroundColor: 'transparent',
  border: 'solid 2px white',
  padding: '1rem 2rem',
  borderRadius: '30px',
  fontWeight: 'bold',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
  marginTop: '2rem',
  '&:hover': {
    backgroundColor: '#424345',
  },
}));

const CallButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '68%',
  left: '4%',
  color: 'white',
  backgroundColor: 'transparent',
  border: 'solid 2px white',
  padding: '1rem 2rem',
  borderRadius: '30px',
   fontWeight: 'bold',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
  textShadow: '2px 2px 4px black', // Add text shadow for better readability
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
  marginTop: '2rem',
  '&:hover': {
    backgroundColor: '#424345',
  },
  '@media (max-width: 600px)': {
    top: '75%',
  },
}));

const Logo = styled('img')({
  position: 'absolute',
  top: '35%', // Reduced top value for computer screens
  left: '4%',
  maxWidth: 'clamp(200px, 10vw, 350px)',
  '@media (max-width: 600px)': {
    top: '15%',
  },
});

const HeroText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '50%', // Reduced top value for computer screens
  left: '4.5%',
  color: 'white',
  fontSize: 'clamp(1.5rem, 3vw, 2rem)',
  transform: 'translateY(-50%)',
  opacity: 0,
  animation: 'swoop 25s infinite',
  '@keyframes swoop': {
    '0%': {
      opacity: 0,
      transform: 'translateY(-70%)',
    },
    '4%, 16%': {
      opacity: 1,
      transform: 'translateY(-50%)',
    },
    '20%, 100%': {
      opacity: 0,
      transform: 'translateY(-70%)',
    },
  },
  '@media (max-width: 600px)': {
    top: '50%',
  },
}));


const ContactInfo = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '0.8rem',
  '@media (max-width: 600px)': {
    fontSize: '0.7rem',
    textAlign: 'left',
    marginTop: '0.5rem',
  },
}));



function HeroSection() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const heroTexts = [
    'Lad os tage os af din rengøring – både før og efter flytning',
    'Professionel rengøring til både private hjem og erhverv',
    'Rengøringstjenester du kan stole på – hver gang',
    'Gør dit kontor skinnende rent med vores ekspertise',
    'Effektiv rengøring med øje for detaljen'
  ];


  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };  

  const [openKontakt, setOpenKontakt] = useState(false);

  const handleClickOpenKontakt = () => {
    setOpenKontakt(true);
  };

  const handleCloseKontakt = () => {
    setOpenKontakt(false);
  };


  return (
    <Hero id="home" >
      <Overlay>
        <AppBar position="static" color="transparent" elevation={0}>
          <StyledToolbar>
            <Typography component="a" variant="h6" href="#home" color="white">
              Hjem
            </Typography>
            <Typography component="a" variant="h6" href="#contact" onClick={() => handleClickOpenKontakt() } color="white">
              Kontakt
            </Typography>
            <Typography component="a" variant="h6" href="#tjenester" color="white">
              Tjenester
            </Typography>
            <Typography component="a" variant="h6" href="#omOs" color="white">
              Om Os
            </Typography>
          </StyledToolbar>

          <Typography
            component="div"
            variant={isSmallScreen ? "h6" : "h3"}
            style={
              isSmallScreen
                ? { textAlign: "left", fontWeight: "bold", marginTop: 40, marginLeft: 30 }
                : { textAlign: "right", fontWeight: "bold", marginTop: 20, marginRight: 50 }
            }
            color="white"
          >
            Ring: 91 64 79 65
          </Typography>

        </AppBar>
        <Logo src={logo} alt="Company Logo" />

        <Dialog open={openKontakt} onClose={handleCloseKontakt}>
          <DialogTitle>Contact Information</DialogTitle>
          <DialogContent>
            <DialogContentText>Tlf: +45 91 64 79 65</DialogContentText>
            <DialogContentText>Email: kontakt@multiclean.dk</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseKontakt}>Close</Button>
          </DialogActions>
        </Dialog>

        {heroTexts.map((text, index) => (
          <HeroText
            key={index}
            variant="h2"
            style={{
              animationDelay: `${index * 5}s`,
            }}
          >
            {text}
          </HeroText>
        ))}


        <ActionButton variant="contained" size="large" onClick={handleClickOpen}>
          Bliv Ringet Op
        </ActionButton>

        <CallButton
          variant="contained"
          size="large"
          href="tel:+4591647965"
        >
          Ring nu
        </CallButton>
        
        <FormDialog open={open} handleClose={handleClose} />

      </Overlay>
    </Hero>
  );
}

export default HeroSection;
