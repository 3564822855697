import React, { useState } from 'react';
import { Box, Typography, Grid, TextField, Button } from '@mui/material';
import { styled } from '@mui/system';
import textImg from '../assets/textImg.png';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useForm } from '@formspree/react';

const TextSectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '5rem 0',
  [theme.breakpoints.down('xs')]: {
    padding: 0,
  },
}));

const TextContainer = styled(Grid)(({ theme }) => ({
  width: '60%',
  margin: '0 auto',
  [theme.breakpoints.down('xs')]: {
    margin: 0,
  },
}));

const Text = styled(Typography)({
  fontSize: '1.25rem',
  color: '#808080',
});

const Title = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '1.25rem',
  color: '#2F2F2F',
});

const Subtitle = styled(Typography)({
  fontSize: '2.5rem',
  color: '#2F2F2F',
});

const ButtonContainer = styled(Box)({
  marginTop: '2rem',
});

const ActionButton = styled(Button)({
  backgroundColor: '#eb7d74',
  color: 'white',
  borderRadius: '30px',
  padding: '1rem 2rem',
  fontWeight: 'bold',
});

function TextSection() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({
    firmanavn: '',
    cvr: '',
    email: '',
    telefonnummer: '',
  });
  const [errors, setErrors] = useState({});
  const [state, handleSubmit] = useForm("xvoenwez"); // Replace with your Formspree endpoint ID

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!form.firmanavn) {
      newErrors.firmanavn = 'Firmanavn er påkrævet';
    }

    if (!form.email || !/\S+@\S+\.\S+/.test(form.email)) {
      newErrors.email = 'Ugyldig email';
    }

    let formattedTelefonnummer = form.telefonnummer.replace(/\s+/g, '');

    if (formattedTelefonnummer.startsWith('0045')) {
      formattedTelefonnummer = formattedTelefonnummer.substring(4);
    } else if (formattedTelefonnummer.startsWith('+45')) {
      formattedTelefonnummer = formattedTelefonnummer.substring(3);
    }

    if (!formattedTelefonnummer || isNaN(formattedTelefonnummer) || formattedTelefonnummer.length !== 8) {
      newErrors.telefonnummer = 'Telefonnummer skal være 8 cifre';
    } else {
      form.telefonnummer = formattedTelefonnummer;
    }

    return newErrors;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const newErrors = validateForm(form);

    if (Object.keys(newErrors).length === 0) {
      handleSubmit(event);
      alert('Tak for din interesse. Vi kontakter dig hurtigst muligt!');
      /*if (state.succeeded) {
        alert('Tak for din interesse. Vi kontakter dig hurtigst muligt!');
      }*/
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <TextSectionContainer id="omOs">
      <TextContainer container spacing={0} justifyContent="space-between">
        <Grid item xs={12} sm={6} sx={{ padding: { xs: '0px' } }}>
          <Title variant="h6">Hvad vi tilbyder</Title>
          <Subtitle variant="h4">
            {isSmallScreen
              ? 'Engageret i renhed og kundetilfredshed gennem detaljeret rengøring, fleksibilitet og pålidelighed'
              : 'Engageret i renhed og kundetilfredshed gennem detaljeret rengøring, fleksibilitet og pålidelighed. Vi mener, at grundighed, tilpasningsdygtighed og konsistent kvalitet er nøglerne til dette mål.'}
          </Subtitle>
          <Text>
            {isSmallScreen
              ? 'At vælge de rigtige rengøringsspecialister er afgørende for at opretholde et rent og indbydende miljø. Vores dygtige medarbejdere leverer en række rengøringstjenester, der sikrer din bolig eller virksomheds renhed og præsentation. Pålitelig og grundig rengøring understøtter et sundt og produktivt miljø.'
              : 'At vælge de rigtige rengøringsspecialister er afgørende for at opretholde et rent og indbydende miljø, hvilket kan føre til øget velvære og produktivitet. Vores erfarne og dygtige rengøringsteam er her for at hjælpe dig med at opnå dette gennem skræddersyede rengøringsløsninger. Vi tilbyder et bredt udvalg af tjenester fra daglig rengøring til specialopgaver, som flytterengøring. Vores pålidelige og effektive tilgang sikrer, at dit rum forbliver pletfrit og præsentabelt, hvilket minimerer din bekymring for vedligehold og renhed på lang sigt.'}
          </Text>
        </Grid>
        <Grid item xs={12} sm={6}>
          {!isSmallScreen && (
            <img
              width="100%"
              style={{ marginLeft: '50px', borderRadius: '10px' }}
              src={textImg}
              alt="Text Section"
            />
          )}
          <form id="contactForm" onSubmit={handleFormSubmit} style={{ marginLeft: '10%', marginTop: '1rem' }}>
            <TextField
              label="Firmanavn"
              name="firmanavn"
              value={form.firmanavn}
              onChange={handleChange}
              error={!!errors.firmanavn}
              helperText={errors.firmanavn}
              style={{ marginTop: '1rem' }}
              fullWidth
            />
            <input type="hidden" name="_gotcha" style={{ display: "none !important" }} />
            <TextField
              label="CVR"
              name="cvr"
              type="number"
              value={form.cvr}
              onChange={handleChange}
              error={!!errors.cvr}
              helperText={errors.cvr}
              style={{ marginTop: '1rem' }}
              fullWidth
            />
            <TextField
              label="Email"
              name="email"
              type="email"
              value={form.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              style={{ marginTop: '1rem' }}
              fullWidth
            />
            <TextField
              label="Telefonnummer"
              name="telefonnummer"
              value={form.telefonnummer}
              onChange={handleChange}
              error={!!errors.telefonnummer}
              helperText={errors.telefonnummer}
              style={{ marginTop: '1rem' }}
              fullWidth
            />
            <ActionButton style={{ marginTop: "5%" }} type="submit" disabled={state.submitting}>Send</ActionButton>
          </form>
        </Grid>
      </TextContainer>
      <ButtonContainer></ButtonContainer>
    </TextSectionContainer>
  );
}

export default TextSection;
