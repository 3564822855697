import React, { useState } from 'react';
import { Typography, Button, Box, Card } from '@mui/material';
import { styled } from '@mui/system';
import backgroundImage from '../assets/PriceCard.png';
import { ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FormDialog from './FormDialog';


const StyledPricingSection = styled(Box)({
  position: 'relative',
  width: '100%',
  height: 'auto',
  minHeight: '90vh',
  backgroundImage: `url(${backgroundImage})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundAttachment: 'fixed',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const CardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const CallToActionButton = styled(Button)({
  color: 'white',
  backgroundColor: 'transparent',
  border: 'solid 2px white',
  padding: '1rem 2rem',
  borderRadius: '30px',
  fontWeight: 'bold',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
  marginTop: '1rem',
  '&:hover': {
    backgroundColor: '#424345',
  },
});

const TransparentCard = styled(Card)(({ theme }) => ({
  background: "linear-gradient(135deg, rgb(249, 68, 242) 0%, rgba(20, 91, 226, 0.62) 20%, rgba(244, 241, 251, 0.61) 100%)",
  backdropFilter: 'blur(4px)',
  padding: '2rem',
  borderRadius: 25,
  margin: 19,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: '55vh', // Set the minimum height you want
  height: '55vh',
  maxWidth: '300px',
  [theme.breakpoints.down('sm')]: {
    margin: '2rem',
    height: 'fit-content',
    width: '80vw',

  },
}));

const PriceBelt = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '80px', // Set the minimum height you want for the belt
  background: 'linear-gradient(135deg, rgba(91, 134, 229, 0.85) 0%, rgba(128, 106, 219, 0.85) 100%)',
  borderRadius: '0 0 25px 25px',
  marginBottom: '1rem',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-20px',
    left: 0,
    width: '50%',
    height: '20px',
    background: 'linear-gradient(135deg, rgba(91, 134, 229, 0.85) 0%, rgba(128, 106, 219, 0.85) 100%)',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '-20px',
    right: 0,
    width: '50%',
    height: '20px',
    background: 'linear-gradient(135deg, rgba(91, 134, 229, 0.85) 0%, rgba(128, 106, 219, 0.85) 100%)',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
}));


const ListContainer = styled(Box)({
  border: '2px solid white',
  borderRadius: '15px',
  padding: '1rem',
  marginBottom: '1rem',
});


const PriceButtonContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});


const TextOutline = styled(Typography)({});
const TextOutlineGratis = styled(Typography)(({ theme, color, fontSize }) => ({
  // ...existing styles...
  fontSize: fontSize, // Use provided fontSize or 'inherit' if not provided
}));
function PricingSection() {

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StyledPricingSection id="tjenester" >
      <CardContainer>
        <TransparentCard>
          <TextOutline variant="h4" style={{maxWidth: "100vw"}} color="white" fontWeight="bold">
            Konsulent Opkald
          </TextOutline>
          <Box display="flex" alignItems="center" mt={1}>
            <ListContainer>
              <Box display="flex" alignItems="center" mt={1}>
                <ListItemIcon>
                  <CheckCircleIcon style={{color:"white"}} />
                </ListItemIcon>
                <ListItemText>
                  <TextOutline color="white" fontWeight="bold" >Konsulent ringer dig op uforpligtende for at høre dine forventninger.</TextOutline>
                </ListItemText>
              </Box>
            </ListContainer>
          </Box>
          <PriceButtonContainer>
            <TextOutlineGratis fontSize="1.8rem" color="white" fontWeight="bold" mt={2}>
              Uforpligtende Samtale
            </TextOutlineGratis>
            <CallToActionButton variant="contained" onClick={handleClickOpen}>
              Kontakt os for et tilbud
            </CallToActionButton>
          </PriceButtonContainer>
        </TransparentCard>

        <TransparentCard>
          <TextOutline variant="h3" color="white" style={{maxWidth: "100vw"}} fontWeight="bold">
            Grundpakke
          </TextOutline>
          <Box display="flex" alignItems="center" mt={1}>
            <ListContainer>
              <Box display="flex" alignItems="center" mt={1}>
                <ListItemIcon>
                  <CheckCircleIcon style={{color:"white"}} />
                </ListItemIcon>
                <ListItemText>
                  <TextOutline color="white" fontWeight="bold" >Rengøring der tilpasses dine behov og frekvens.</TextOutline>
                </ListItemText>
              </Box>
            </ListContainer>
          </Box>
          <PriceButtonContainer>
            <CallToActionButton variant="contained" onClick={handleClickOpen}>
              Kontakt os for et tilbud
            </CallToActionButton>
          </PriceButtonContainer>
        </TransparentCard>

        <TransparentCard>
          <TextOutline variant="h3" color="white" style={{fontSize: "2.2rem"}} fontWeight="bold">
            Rengøringsaftale{' '}
          </TextOutline>
          <Box display="flex" alignItems="center" mt={1}>
            <ListContainer>
              <Box display="flex" alignItems="center" mt={1}>
                <ListItemIcon>
                  <CheckCircleIcon style={{color:"white"}} />
                </ListItemIcon>
                <ListItemText>
                  <TextOutline color="white" fontWeight="bold" >Individuel og tilpasset service tilpasset til store rengøringsprojekter.</TextOutline>
                </ListItemText>
              </Box>
            </ListContainer>
          </Box>
          <PriceButtonContainer>
            <CallToActionButton variant="contained" onClick={handleClickOpen}>
              Kontakt os for et tilbud
            </CallToActionButton>
          </PriceButtonContainer>
        </TransparentCard>

        <FormDialog open={open} handleClose={handleClose} />
      </CardContainer>
    </StyledPricingSection>

  );
}

export default PricingSection;
