import React from 'react';
import { Box, Typography, Grid, Avatar } from '@mui/material';
import { styled } from '@mui/system';
import dinesJPG from '../assets/dines.jpg';
import thomasJPG from '../assets/thomas.jpg';

const TeamSectionContainer = styled(Box)({
  padding: '5rem 0',
  textAlign: 'center',
});

const TeamMemberCard = styled(Box)({
  padding: '1rem',
});

const TeamAvatar = styled(Avatar)({
  width: '100px',
  height: '100px',
  margin: '0 auto',
});

const TeamName = styled(Typography)({
  fontWeight: 'bold',
  marginTop: '1rem',
});

const TeamTitle = styled(Typography)({
  fontStyle: 'italic',
  marginBottom: '1rem',
});

const TeamDescription = styled(Typography)({
  fontSize: '1rem',
});

function TeamSection() {
  return (
    <TeamSectionContainer>
      <Typography variant="h4">Mød Vores Team</Typography>
      <Grid container spacing={4} justifyContent="center" alignItems="center" style={{ marginTop: '2rem' }}>
        <Grid item xs={12} sm={4}>
          <TeamMemberCard>
            <TeamAvatar src={dinesJPG} alt="Team Member 1" />
            <TeamName variant="h6">Dines M. Janagarajah</TeamName>
            <TeamTitle variant="subtitle1">Salgs Konsulent</TeamTitle>
            <TeamDescription>
              Dines M. Janagarajah er en serieiværksætter inden for serviceindustrien med stor erfaring inden for salg. Han spiller en afgørende rolle i virksomhedens succes og er en specialist inden for sit felt. Med en baggrund i salg og service er han ansvarlig for at lede og udvikle vores strategier for at sikre fremragende kundeoplevelser og øge vores markedsandel.
            </TeamDescription>
          </TeamMemberCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TeamMemberCard>
            <TeamAvatar src={thomasJPG} alt="Team Member 2" />
            <TeamName variant="h6">Thomas Nielsen</TeamName>
            <TeamTitle variant="subtitle1">Financiel Konsulent</TeamTitle>
            <TeamDescription>
              Thomas Nielsen er erfaren og professionel med en alsidig baggrund, der bringer ekspertise inden for regnskab og bogholderi til vores team. Som leder af vores organisationen fokuserer Thomas på at optimere jeres forretningsprocesser og sikre, at jeres virksomhed opnår bæredygtig vækst. Med sin brede erfaring inden for forskellige områder er Thomas en værdifuld ressource, der hjælper jer med at nå jeres mål og sikre jer succes på lang sigt.            </TeamDescription>
          </TeamMemberCard>
        </Grid>
      </Grid>
    </TeamSectionContainer>
  );
}

export default TeamSection;
